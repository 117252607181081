<template>
    <div class="container-fluid">
        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t('room.index.title')}}</h4>
                    <label v-show="info_page.status===true">{{$t('room.index.has')+' '+ info_page.actives+' '+$t('room.index.a_has_plural') }}</label>
                    <label v-show="info_page.status===false">{{$t('room.index.has')+' '+ info_page.inactives+' '+$t('room.index.i_has_plural') }}</label>
                </div>
                <div class="col-md-5 pt-2">
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='active'?'btn-success':'btn-outline'" @click="change_status('active')">{{$t('form.actives')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='inactive'?'btn-success':'btn-outline'" @click="change_status('inactive')">{{$t('form.inactives')}}</button>
                </div>
            </div>
        </div>



    <div class="row">

        <div class="col-12">
            <card v-loading="screenLoading"
                  element-loading-lock="true"
                  element-loading-customClass="min-height-large"
                  :element-loading-text="screenText"
                  element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-3" style="width: 200px">

                            <l-button class="btn-default " @click="createItem()" v-if="writting_permission" >{{$t('room.index.new')}}</l-button>
                        </div>
                        <el-input type="search"
                                  class="mb-3"
                                  style="width: 200px"
                                  :placeholder="$t('room.index.search') "
                                  v-model="searchQuery"
                                  aria-controls="datatables"/>
                    </div>
                    <div class="col-sm-12">

                        <el-table class="table-bigboy"
                                  style="width: 100%"
                                  :data="queriedData">
                            <el-table-column min-width="150"  :label="$t('room.index.title') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="120"  :label="$t('room.index.cod') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.cod}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="150"  :label="$t('room.index.contracts') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center"  >
                                        <label v-for="rateconfig in getRateplans(row.rateconfig)">{{rateconfig.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="150"  :label="$t('room.form.capacity_max') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.max_pax}} max</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="120"  :label="$t('room.index.types') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center custom-lbl">
                                        <div v-for="item in printConsole(row)">
                                            <label>{{item.name}}</label>
                                            <span v-for="itm in item.configs">{{itm}}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column  min-width="150"  :label="$t('form.actions')" v-if="writting_permission">
                                <template slot-scope="{row}">
                                    <div class="text-center">

                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status)"  v-if="row.rateconfig && getRateplans(row.rateconfig).length ===0"></l-switch>


                                        <span class="fa fa-lock icon-right cursor-undrop"  v-if="row.rateconfig && getRateplans(row.rateconfig).length" v-tooltip.bottom="'Desactivar contratos antes de continuar'"  ></span>
                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(row._id)"><i
                                                class="el-icon-edit"></i></a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>
        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1"  @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="saveChanges">Guardar</button>
            </div>
        </div>
    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import RoomService from '../../../js/services/RoomService';
    import ContractService from '../../../js/services/ContractService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    const roomService = new RoomService();
    const contractService = new ContractService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();

    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import moment from 'moment';


    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser","selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    //result = this.fuseSearch.search(this.searchQuery)
                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            }
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records:[],
                info_page:{
                    original_info:{},
                    section_name: 'room',
                    status: true,
                    actives: 0,
                    inactives: 0,
                    contracts: []
                },
                items: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,

                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                section: 'ROOMS_RATES'
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='Rooms'){
                       //console.log(this.$route.name, 'rooms')
                        this.chargerItems(property_id);
                    }
                })
            },

            checkedAction(){
                EventBus.$on('CHECKED_VIEW', (checked) => {
                    console.log('CHECKED_VIEW room', this.records.length);
                    if(this.records.length){
                        this.$store.dispatch('CHANGES_ACTIVES', true);
                    }else{
                        this.$store.dispatch('CHANGES_ACTIVES', false);
                    }
                })
            },
            sortByKey(array, key) {
                return array.sort(function(a, b) {
                    var x = a[key]; var y = b[key];
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                });
            },
          


            async chargerItems(property_id = null){
                this.initLoading();
                let contracts = await contractService.getContractsAll(property_id);
                contracts = contracts.data.data.rateplans
                this.change_status('active');
                this.info_page.contracts = contracts;

                this.configLogs(property_id, this.info_page.section_name);
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            handleEdit(id) {
                this.$router.push({
                    name: 'editRoom',
                    params: {
                        id: id
                    }
                })
            },
            printConsole(row){

                let room_configs = [];
                if(row.room_configs){
                    room_configs = row.room_configs;
                }

                //console.log('room_configs', room_configs)
                let response = [];




                if(room_configs.length>0){
                    room_configs.forEach((item, idx)=>{
                        let roomConfigs = [];


                        if(item.single>0){
                            roomConfigs.push('Individual')
                        }
                        if(item.double>0){
                            roomConfigs.push('Matrimonial')
                        }
                        if(item.king>0){
                            roomConfigs.push('King Size')
                        }
                        if(item.queen>0){
                            roomConfigs.push('Queen Size')
                        }
                        if(item.couch>0){
                            roomConfigs.push('Sofa cama')
                        }
                        if(item.bunk>0){
                            roomConfigs.push('Litera')
                        }
                        let option = {
                            name: 'Opción '+ (idx+1),
                            configs: roomConfigs

                        }

                        response.push(option)
                    })
                    //console.log('response', response)
                    return response;
                }

            },
            handleDelete(row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        roomService.deleteRoom(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });
                    }
                });



            },
            createItem(){

                this.$router.push({
                    name: 'createRoom'
                })
            },

            cancelForm(){
                this.chargerItems(this.selected_property._id);
                this.info_page.status = true;
                this.records = [];
                this.$store.dispatch('CHANGES_ACTIVES', false);
                /*let tableData = this.tableData;
                this.records.forEach(item => {
                    let element = tableData.find(xquery => xquery._id === item._id)
                    element.status = !element.status;
                })
                this.records = [];*/
            },


            updateStatus(id, status){
                let records = this.records;
                status = !status;
                this.$store.dispatch('CHANGES_ACTIVES', true);

                let idx = records.findIndex(record => record._id === id);
                if(idx>=0){
                    records.splice(idx, 1);
                }else{
                    records.push({
                        _id: id,
                        status: status
                    })
                }
            },


            saveChanges(){
               //console.log('items modificados', this.records)
                let records = this.records;

                this.initLoading('Actualizando habitaciones');
                records.forEach(async (item, idx)=>{
                    let status =item.status;
                    let id =item._id;
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'false';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'true';
                    }

                    let room_resp = await roomService.updateStatus(id, status)

                    //console.log('contract response', contract.data.data.rateplan)
                    let room = room_resp.data.data.room;


                    if(room.status===true){
                        await this.items.push(room)
                        let data =  [{
                            parameter : "status",
                            old : false,
                            new : true
                        }];
                        await this.createLog(room._id, 1, data);
                    }else{
                        await this.items_i.push(room)
                        await this.createLog(room._id, 2, []);
                    }
                    this.info_page.actives =  this.items.length;
                    this.info_page.inactives =  this.items_i.length;

                });
                this.closeLoading();
                this.records = [];

            },

            async change_status(type){
                this.initLoading();
                let items = [];
                this.info_page.status = type;

                let property_id = this.selected_property._id;
                let params = {};

                if(type === 'active'){
                    params = {
                        property: property_id,
                        status: true
                    }
                }else if(type === 'inactive'){
                    params = {
                        property: property_id,
                        status: false
                    }
                }
                //console.log('params', params);

                let responseRequest =  await roomService.getRoomsList(params)
                responseRequest = responseRequest.data.data.rooms;
                this.info_page.actives = responseRequest.length;
                this.tableData = responseRequest;
                this.closeLoading();
            },

            /*change_status(status){
                this.info_page.status=status;
                //console.log(this.info_page.actives, this.info_page.inactives)

                if(status){
                    this.tableData =  this.items;
                }else{
                    this.tableData =  this.items_i;
                }
            },*/
            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },
            createStars(number){
                //number = 4.5;
                let complete_stars = Math.floor(number);
                let last_stars = (complete_stars!==number)?'<i class="fa fa-star-half-o""></i>':'';

                let result = '';
                for(let i=1; i<=number; i++){
                    result+='<i class="fa fa-star"></i>';
                }
                return result + last_stars;
            },
            getRateplans(rateConfigs){

                let contracts = this.info_page.contracts;
                let date_now = moment();
                //console.log('rateConfigs array', rateConfigs);
                if(rateConfigs){
                    let arrayResult = rateConfigs.map(itm => ({
                        ...contracts.find((item) => (item._id === itm.rateplan) && item),
                        ...itm }));
                    return arrayResult.filter(element => element.status && moment(date_now.format('YYYY-MM-DD')).isBefore(element.to, 'day'))
                }

                return [];
            },
            findContract(contract_id){
                let contracts = this.info_page.contracts;
                //console.log('contract_id', contract_id, contracts)

                //let aux = date
                let date_now = moment();



                    if(contract_id){
                    let ratePlan = contracts.find(contract => contract._id===contract_id && contract.status && moment(date_now.format('YYYY-MM-DD')).isBefore(contract.to, 'day'));
                    //console.log('ratePlan', ratePlan)
                    return (ratePlan)?ratePlan.name:''
                }



            },
            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },
        mounted() {
            this.changeProperty()
            this.checkedAction();
        }
    }
</script>
<style lang="scss">
    .custom-lbl{
        label{
            color: #1FBF64;
            font-weight: 600;
        }
        span{
            width: 100%;
            display: inline-block;

        }
    }
</style>
